// 微信分享实例
import sha1 from 'sha1'
import { http } from '@/http'

const weixin = function (window, wx) {

  function setup(options = {}) {
    let config = Object.assign({}, options)
    let configData = {
      imgUrl: config.imgUrl,//"https://online-lazypig.lazipig.com/icon500.png",
      title: config.title, // "猪耳朵商户注册", // 分享标题
      desc: config.desc, //"猪耳朵省时、省力、更省钱，聚合平台领跑者", // 分享描述
      link: config.link //"https://devh5.pig-ear.com/#/signup",
    }
    initConfig(config)
    // checkJsApi()
    wx.ready((e) => {
      shareReady(configData)
    })
  }

  function getSignature(config) {
    return http.post('/?cmd=com.xqxc.api.pigear.biz.weixin.WeixinShareService.getRespJson', {
      url: config.shareUrl
    })
  }

  function initConfig(config) {
    // let dataConfig = localStorage.getItem('_shareData')
    // if(dataConfig !== null) {
    //   receiveConfigData(JSON.parse(dataConfig))
    // }
    // else {
    // 每次进入需要重新请求
    getSignature(config).then(res => {
      receiveConfigData(res.result)
      // window.localStorage.setItem('_shareData', JSON.stringify(res.result))
    })
    // }
  }

  function checkJsApi() {
    wx.checkJsApi({
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'updateAppMessageShareData',
        'updateTimelineShareData',
      ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
      success: function (res) {
      },
      fail: function () {

      }
    })
  }

  function receiveConfigData(result) {
    let appId = result.appId
    let jsapi_ticket = result.ticket
    let timestamp = Date.parse(new Date()) / 1000
    let nonceStr = result.nonceStr
    let signature = `jsapi_ticket=${jsapi_ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${location.href.split('#')[0]}`
    wx.config({
      debug: false,
      appId: appId, // 必填，公众号的唯一标识
      timestamp: timestamp, // 必填，生成签名的时间戳
      nonceStr: nonceStr, // 必填，生成签名的随机串
      signature: sha1(signature), // 必填，签名
      jsApiList: [
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'updateAppMessageShareData',
        'updateTimelineShareData'
      ],
    })
  }
  function toNum(a) {
    a = a.toString()
    var c = a.split('.')
    var num_place = ['', '0', '00', '000', '0000'],
      r = num_place.reverse()
    for (var i = 0; i < c.length; i++) {
      var len = c[i].length
      c[i] = r[len] + c[i]
    }
    var res = c.join('')
    return res
  }

  function isWeiXinNewVersionToShare() {
    let version = window.navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)[1]
    let lineVersion = '6.7.2'
    var a = toNum(version)
    var b = toNum(lineVersion)
    return a >= b
  }

  function shareReady(configData) {
    if (isWeiXinNewVersionToShare()) {
      wx.updateAppMessageShareData({
        title: configData.title,
        desc: configData.desc,
        link: configData.link,
        imgUrl: configData.imgUrl,
        type: 'link',
        success: function () { },
      })
      wx.updateTimelineShareData({
        title: configData.title,
        link: configData.link,
        imgUrl: configData.imgUrl,
        success: function () { },
      })
    } else {
      //分享到朋友圈
      wx.onMenuShareTimeline({
        title: configData.title,
        link: configData.link,
        imgUrl: configData.imgUrl,
        success: function () { },
      })
      //分享给朋友
      wx.onMenuShareAppMessage({
        title: configData.title,
        link: configData.link,
        imgUrl: configData.imgUrl,
        success: function () { },
      })
      //分享到qq
      wx.onMenuShareQQ({
        title: configData.title,
        link: configData.link,
        imgUrl: configData.imgUrl,
        success: function () { },
      })
      //分享到腾讯微博
      wx.onMenuShareWeibo({
        title: configData.title,
        link: configData.link,
        imgUrl: configData.imgUrl,
        success: function () { },
      })
      //分享到QQ空间
      wx.onMenuShareQZone({
        title: configData.title,
        link: configData.link,
        imgUrl: configData.imgUrl,
        success: function () { },
      })
    }
  }
  return {
    setup
  }

}(window, window.wx)

export default weixin
